<template>
  <div class="container">
    <div>
      <div style="padding: 10px;box-sizing: border-box;"><img class="mb_logo" src="../../assets/image/logo.png" alt="" /></div>
      <div class="row" style="justify-content: space-around;">
        <div @click="titChange(1)" :class="[titType == 1 ? 'pitchon' : 'unselected']">
          首页
        </div>
        <div @click="titChange(2)" :class="[titType == 2 ? 'pitchon' : 'unselected']">
          下载
        </div>
        <div @click="titChange(3)" :class="[titType == 3 ? 'pitchon' : 'unselected']">
          价格
        </div>
        <div @click="titChange(4)" :class="[titType == 4 ? 'pitchon' : 'unselected']">
          常见问题
        </div>
        <!-- <div
          @click="titChange(5)"
          :class="[titType == 5 ? 'pitchon' : 'unselected']"
        >
          登录
        </div> -->
      </div>
    </div>
    <div class="middle"><router-view></router-view></div>
    <div style="height: 144px;">
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "../../components/mobile/footer.vue";
export default {
  components: {
    Footer,
  },
  data() {
    return {
      titType: 1,
    };
  },
  mounted() {
    if (sessionStorage.getItem("titType")) {
      this.titType = sessionStorage.getItem("titType");
    }
  },
  methods: {
    titChange(type) {
      this.titType = type;
      sessionStorage.setItem('titType', type);
      if (type == 1) {
        this.$router.push("/m_index");
      } else if (type == 2) {
        this.$router.push("/m_download");
      } else if (type == 3) {
        this.$router.push("/m_price");
      } else if (type == 4) {
        this.$router.push("/m_downsan");
      } else if (type == 5) {
        window.open('https://pmz.pmzaa.com/')
      }
    },
  },
};
</script>



<style scoped>
.mb_logo {
  height: 80px;
}

.row {
  display: flex;
}

.container {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.middle {
  flex: 1;
  background: linear-gradient(180deg, #F9FCFF 0%, #A4CCE6 100%);

}

.pitchon {
  height: 40px;
  background: #3f8cff;
  line-height: 40px;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  padding: 0 15px;
  border-radius: 3px;
}

.unselected {
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #182727;
  padding: 0 15px;
}

.el-header,
.el-footer {
  background-color: #ffffffff;
  color: #333;
  text-align: center;
  line-height: 60px;
  height: 60px;
}
</style>